@use 'sass:math';
@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';

// See https://mantine.dev/styles/sass/ for the below

$mantine-breakpoint-xs: '36em';
$mantine-breakpoint-sm: '48em';
$mantine-breakpoint-md: '62em';
$mantine-breakpoint-lg: '75em';
$mantine-breakpoint-xl: '88em';

@function rem($value) {
  @return #{math.div(math.div($value, $value * 0 + 1), 16)}rem;
}

@mixin light {
  [data-mantine-color-scheme='light'] & {
    @content;
  }
}

@mixin dark {
  [data-mantine-color-scheme='dark'] & {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  @media (hover: none) {
    &:active {
      @content;
    }
  }
}

@mixin smaller-than($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin larger-than($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin rtl {
  [dir='rtl'] & {
    @content;
  }
}

@mixin ltr {
  [dir='ltr'] & {
    @content;
  }
}

.mantine-Input-input {
  font-size: var(--bulma-size-normal);
  padding-top: calc(0.5em - 1px);
  padding-bottom: calc(0.5em - 1px);
}

.DateSelect-root .mantine-Input-input {
  line-height: inherit;
}

.DateSelect-named-options {
  font-size: var(--mantine-font-size-sm);
}

.DateSelect-named-options .mantine-Divider-root {
  margin: 10px 0;
}

.DateSelect-named-options .selected {
  font-weight: 500;
}

// Undo our reset/generic stylesheets overriding th's text-align to inherit
table th.mantine-DatePickerInput-weekday,
table th.mantine-DatePicker-weekday {
  text-align: center;  
}

// Don't make weekends red, that looks a bit too much like they're a problem
.mantine-DatePickerInput-day:where([data-weekend]),
.mantine-DatePicker-day:where([data-weekend]) {
  color: inherit;
}

// Color the selected data range text, like our BI tool's inputs
.mantine-DatePickerInput-day:where([data-in-range]),
.mantine-DatePickerInput-day:where([data-weekend]):where([data-in-range]),
.mantine-DatePicker-day:where([data-in-range]),
.mantine-DatePicker-day:where([data-weekend]):where([data-in-range]) {
  color: var(--mantine-primary-color-light-color);
}

.mantine-DatePickerInput-day:where([data-in-range]):where([data-selected]),
.mantine-DatePicker-day:where([data-in-range]):where([data-selected]) {
  color: var(--mantine-primary-color-contrast);
}

// Increase the store selection pill size from -sm to -md
.mantine-Pill-root {
  height: var(--pill-height-md);
}

.mantine-Pill-label {
  font-size: var(--pill-fz-md);
  line-height: var(--pill-height-md);
}

// Make the edge of the combobox dropdowns more visible using a box-shadow, like our BI tool's filter inputs.
.mantine-Popover-dropdown {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 0px;
}